import React, { useState, useEffect } from "react";
import { pb } from "./../pb.js";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";

// import required modules
import { Autoplay, FreeMode, Pagination } from "swiper/modules";

export const Companies = () => {
  const [clients, setClients] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const logos = [
    "https://tailwindui.com/img/logos/158x48/transistor-logo-gray-900.svg",
    "https://tailwindui.com/img/logos/158x48/reform-logo-gray-900.svg",
    "https://tailwindui.com/img/logos/158x48/tuple-logo-gray-900.svg",
    "https://tailwindui.com/img/logos/158x48/savvycal-logo-gray-900.svg",
    "https://tailwindui.com/img/logos/158x48/statamic-logo-gray-900.svg",
    "https://tailwindui.com/img/logos/laravel-logo-gray-900.svg",
  ];

  useEffect(() => {
    // Fetch data from PocketBase where features is true, sorted by position ascending
    const fetchClients = async () => {
      try {
        const records = await pb.collection("client").getList(1, 50, {
          filter: "features=true", // Only clients where features is true
          sort: "position", // Sort by position in ascending order
        });

        setClients(records.items); // Store the fetched clients
        setLoading(false);
      } catch (err) {
        console.error("Error fetching clients:", err);
        setError("Failed to fetch clients.");
        setLoading(false);
      }
    };

    fetchClients();
  }, []);

  if (loading)
    return (
      <section className="relative  bg-[radial-gradient(45rem_50rem_at_top,theme(colors.blue.100),white)]">
        <div className="container mx-auto max-w-7xl pt-32 pb-24 px-5 md:px-0">
          <div>
            <p className="font-semibold text-blue-600">OUR CLIENTS</p>
            <div className=" ">
              <div>
                <h2 className="mt-2 ">
                  Trusted by Visionary Brands and Businesses
                </h2>
                <p className="mt-3 text-md font-extralight text-neutral-500 md:w-2/3 ">
                  Our clients span diverse industries, all sharing a commitment
                  to innovation and excellence. We take pride in delivering
                  tailored graphic and web design solutions that drive their
                  success.
                </p>
              </div>
              <div className="  grid md:grid-cols-6 grid-cols-2 gap-x-4 gap-y-4 mt-8">
                {logos &&
                  logos.map((src) => (
                    <div class="skeleton h-20 bg-slate-50 "></div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </section>
    );

  return (
    <section className="relative  bg-[radial-gradient(45rem_50rem_at_top,theme(colors.blue.100),white)]">
      <div className="container mx-auto max-w-7xl pt-32 pb-24 px-5 md:px-0">
        <div>
          <p className="font-semibold text-blue-600">OUR CLIENTS</p>
          <div className=" ">
            <div>
              <h2 className="mt-2">
                Trusted by Visionary Brands and Businesses
              </h2>
              <p className="mt-3 text-md font-extralight text-neutral-500 md:w-2/3 ">
                Our clients span diverse industries, all sharing a commitment to
                innovation and excellence. We take pride in delivering tailored
                graphic and web design solutions that drive their success.
              </p>
            </div>

            <Swiper
              slidesPerView={2}
              spaceBetween={6}
              freeMode={true}
             
              modules={[FreeMode, Autoplay]}
              className="mySwiper mt-8"
              autoplay={{
                delay: 2000,
                disableOnInteraction: false,
              }}
              loop={true} // Enable loop if needed
              breakpoints={{
                640: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                },
                768: {
                  slidesPerView: 4,
                  spaceBetween: 40,
                },
                1024: {
                  slidesPerView: 4,
                  spaceBetween: 50,
                },
              }}
            >
              {clients &&
                clients.map((src) => (
                  <SwiperSlide className="px-0  py-3  bg-white rounded-xl grayscale contrast-200">
                    <img
                      alt={src.name}
                      src={`${process.env.REACT_APP_FILES_URL}${src.collectionId}/${src.id}/${src.logo}`}
                      className=" w-full     "
                    />
                  </SwiperSlide>
                ))}
            </Swiper>
          </div>
        </div>
      </div>
    </section>
  );
};
