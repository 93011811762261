import React from 'react'
import { Navbar } from '../comp/Navbar'

export const Start = () => {
  return (
    <div>
        
    </div>
  )
}
