const sendEmail = async (to, name, subject, text) => {
    try {
      const response = await fetch(process.env.REACT_APP_EMAIL_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': process.env.REACT_APP_EMAIL_API_KEY,
        },
        body: JSON.stringify({
          to,
          subject,
          text, // Use the passed text as the HTML body
        }),
      });
  
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Something went wrong!');
      }
  
      const data = await response.json();
      console.log('Email sent successfully');
      return { success: true, data };
    } catch (error) {
      console.error('Error:', error.message);
      return { success: false, message: error.message };
    }
  };
  
  export default sendEmail;