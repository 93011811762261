
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import { TrackPage } from './TrackPage';

import { UserLayout } from './UserLayout';
import { PageNotFound } from './routes/PageNotFound';
import SaveUTM from './services/SaveUTM';



function App() {
 
  return (
    <Router>
    <TrackPage/>
    <SaveUTM/>
      <Routes>
        <Route path='/*' element={<UserLayout/>} />
        <Route path='/error404' element={<PageNotFound/>} />
       
      </Routes>
    </Router>
  );
}

export default App;
