import React, { useState } from "react";
import logo from "./../img/logo.svg";
import { NavLink } from "react-router-dom";

export const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const closeMenu = () => {
    setIsOpen(false);
  };

  const links = [
    {
      id : 1,
      url : '/',
      name : 'Home'
    },
    {
      id : 2,
      url : '/portfolio',
      name : 'Portfolio'
    },
    {
      id : 3,
      url : '/services',
      name : 'Services'
    },
    {
      id : 4,
      url : '/blogs',
      name : 'Blogs'
    },
    {
      id : 5,
      url : '/contact',
      name : 'Contact'
    }
  ]

  return (
    <div className="bg-white z-10 border-t-4 border-primary md:border-t-0 relative">
      <div className="container mx-auto flex items-center justify-between px-5 md:px-0">
        {/* Logo */}
        <img src={logo} className="h-20" alt="Logo" />

        {/* Desktop Navigation */}
        <ul className="md:flex space-x-5 items-center   text-lg text-slate-500 hidden">
          {links.map((item)=>(<li>
            <NavLink
              to={item.url}
              className={({ isActive }) =>
                `border-b-[3px] h-full px-4 pb-2 t ${
                  isActive
                    ? "border-b-blue-600"
                    : "border-transparent hover:border-b-slate-200"
                }`
              }
            >
              {item.name}
            </NavLink>
          </li>))}
          
 
 
        </ul>

        {/* Hamburger Menu Icon for Mobile */}
        <div className="md:hidden block cursor-pointer" onClick={toggleMenu}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-12 h-12"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M3.75 6.75h16.5M3.75 12h16.5M12 17.25h8.25"
            />
          </svg>
        </div>

        {/* Full-Screen Mobile Menu */}
        {isOpen && (
          <div className="fixed inset-0 bg-blue-700 bg-opacity-90 backdrop-blur  flex flex-col items-center justify-center text-white z-50 md:hidden ">
            <button
              className="absolute top-4 right-7 text-white text-5xl font-extralight"
              onClick={closeMenu}
            >
              &times;
            </button>
            <ul className="space-y-8 text-4xl font-extralight text-center">
              {links.map((link)=>(
                 <li onClick={closeMenu}>
                 <NavLink to={link.url} activeClassName="text-blue-600">
                   {link.name}
                 </NavLink>
               </li>
              ))}
          
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};