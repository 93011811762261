import React, { useState } from "react";
import { servicesData } from "../data";

export const Services = () => {
  const [active, setActive] = useState(1);
  const activeService = servicesData.find((service) => service.id === active);

  return (
    <div className="bg-black pt-32 md:pb-40 pb-28 ">
      <div className=" mx-auto max-w-7xl">
        {/* <div className="md:w-1/2 w-full mt-5 md:mt-0">
          {activeService && (
            <div className=" p-2 md:p-5 bg-primary rounded-xl">
              <div
                className=" h-[250px] md:h-[300px] rounded-lg bg-center bg-cover bg-no-repeat flex justify-end"
                style={{ backgroundImage: `url(${activeService.image})` }}
              >
                <span className="absolute">
                  <div className="px-6  cursor-pointer pt-1 shadow-lg pb-3 bg-primary  flex md:text-xl text-white  rounded-bl-2xl items-center group relative">
                    Start Project
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="size-6 ml-2 transform duration-150 group-hover:translate-x-3"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M17.25 8.25 21 12m0 0-3.75 3.75M21 12H3"
                      />
                    </svg>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="33.131"
                      height="33.234"
                      viewBox="0 0 33.131 33.234"
                       className=" md:w-6 md:h-6 h-4 w-4 absolute  top-0 md:-left-6 -left-4 fill-primary"
                    >
                      <path
                        id="Subtraction_20"
                        data-name="Subtraction 20"
                        d="M0,0H0V33.234h33.13A33.318,33.318,0,0,1,20.461,30.6,33.388,33.388,0,0,1,9.813,23.422,33.388,33.388,0,0,1,2.634,12.774,33.28,33.28,0,0,1,0,0Z"
                        transform="translate(33.131 33.234) rotate(180)"
                       
                      />
                    </svg>

                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="33.131"
                      height="33.234"
                      viewBox="0 0 33.131 33.234"
                       className=" md:w-6 md:h-6 h-4 w-4 absolute  md:-bottom-6 -bottom-4 right-0 fill-primary"
                    >
                      <path
                        id="Subtraction_20"
                        data-name="Subtraction 20"
                        d="M0,0H0V33.234h33.13A33.318,33.318,0,0,1,20.461,30.6,33.388,33.388,0,0,1,9.813,23.422,33.388,33.388,0,0,1,2.634,12.774,33.28,33.28,0,0,1,0,0Z"
                        transform="translate(33.131 33.234) rotate(180)"
                       
                      />
                    </svg>

                  </div>
                </span>
              </div>
              <p className="text-white md:text-lg font-light mt-4 md:p-5 p-3 text-sm  ">
                {activeService.description}
              </p>
            </div>
          )}
        </div> */}

        <div className=" w-full">
          <p className="text-amber-500">WHAT WE'RE GOOD AT</p>
          {servicesData.map((service, index) => (
            <div
              data-aos="fade-up"
              data-aos-delay={index * 200}
              key={service.id || index}
              className={`border-b-neutral-800 flex  justify-between items-center group text-white/50 hover:border-neutral-700 duration-150 hover:text-white md:py-8 py-4 cursor-pointer border-b last:border-none group `}
            >
              <div>
                <span className="mr-3 font-semibold hidden md:inline-block">
                  0{service.id}
                </span>
                <span className="md:text-5xl text-3xl  font-extralight  ">
                  {service.name}
                </span>
              </div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="md:size-8 size-6 hidden group-hover:inline"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m4.5 19.5 15-15m0 0H8.25m11.25 0v11.25"
                />
              </svg>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
