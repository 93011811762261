import React, { useEffect, useState } from "react";
import { pb } from "../pb";
import { toast, Toaster } from "sonner";
import sendEmail from "../sendmail";
import { Helmet } from "react-helmet";

export const Contact = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    company: "",
    message: "",
  });
  const [errors, setErrors] = useState({});
  const [dissable, setDissable] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const validateForm = () => {
    let newErrors = {};
    if (!formData.name) newErrors.name = "Name is required";
    if (!formData.email) newErrors.email = "Email is required";
    if (!formData.phone) newErrors.phone = "Phone is required";
    if (!formData.company) newErrors.company = "Company is required";
    if (!formData.message) newErrors.message = "Message is required";
    return newErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setDissable(true);
    
    // Validate the form and set errors
    const newErrors = validateForm();
    setErrors(newErrors);
  
    // Extract data from form
    const to = formData.email;
    const name = formData.name;
    const subject = `Thank you ${name}`;
    const text = `
    <p>Hello ${name},</p>
    <p>Thank you for contacting <strong>GraphWizards Design Co.</strong>! We appreciate your interest in our services.</p>
    <p>Best wishes,<br>GraphWizards Design Co. Team</p>
  `;
  
    // Check for errors before proceeding
    if (Object.keys(newErrors).length === 0) {
      try {
        // Save data to Pocketbase
        const record = await pb.collection("query").create(formData);
        
        // Send email
        const result = await sendEmail(to, name, subject, text);

        if (result.success) {
          
        } else {
          console.log(result);
          
        }
  
        // Show success notification
        toast.success("Thank You!", {
          position: "bottom-center",
          description: "Your message has been successfully submitted. Our team will get in touch with you shortly.",
        });
  
        // Reset form data
        setFormData({
          name: "",
          email: "",
          phone: "",
          company: "",
          message: "",
        });

        setDissable(false)
        
      } catch (error) {
        console.error("Error submitting form:", error);
        toast.error("There was an error submitting your message. Please try again.");
      }
    }
  };

  const social = [
    {
      id: 1,
      url: "https://www.instagram.com/graph_wizards",
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          strokeWidth="1.5"
          viewBox="0 0 22 22"
          stroke="currentColor"
          className="h-6 w-6"
        >
          <g id="Icon" transform="translate(2.29 3.499)">
            <rect
              id="rect"
              width="20"
              height="20"
              rx="5"
              transform="translate(-1.29 -2.499)"
              fill="none"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              id="path"
              d="M16,11.37A4,4,0,1,1,12.63,8,4,4,0,0,1,16,11.37Z"
              transform="translate(-3.29 -4.499)"
              fill="none"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <line
              id="line"
              x2="0.01"
              transform="translate(14.21 2.001)"
              fill="none"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
        </svg>
      ),
    },
    {
      id: 2,
      url: "https://www.facebook.com/designcanmiracle",
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          strokeWidth="1.5"
          viewBox="0 0 22 22"
          stroke="currentColor"
          className="h-6 w-6"
        >
          <g id="Icon" transform="translate(0 0.75)">
            <rect id="Area" width="20" height="20" fill="#fcfcfc" opacity="0" />
            <g id="Icon-2" data-name="Icon" transform="translate(1.29 2.499)">
              <path
                id="_095c0c68-2bb9-4ef5-9f46-f991b1bc088b"
                data-name="095c0c68-2bb9-4ef5-9f46-f991b1bc088b"
                d="M18,2H15a5,5,0,0,0-5,5v3H7v4h3v8h4V14h3l1-4H14V7a1,1,0,0,1,1-1h3Z"
                transform="translate(-3.79 -4.499)"
                fill="none"
                stroke="#3d3d3d"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1.5"
              />
            </g>
          </g>
        </svg>
      ),
    },
    {
      id: 3,
      url: "https://www.linkedin.com/company/graphwizards",
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          strokeWidth="1.5"
          viewBox="0 0 22 22"
          stroke="currentColor"
          className="h-6 w-6"
        >
          <g id="Icon" transform="translate(0.75 0.25)">
            <rect id="Area" width="20" height="20" fill="#fcfcfc" opacity="0" />
            <g id="Icon-2" data-name="Icon" transform="translate(1.29 2.499)">
              <path
                id="daf8d506-7035-4507-8cc3-26bb05760a6a"
                d="M16,8a6,6,0,0,1,6,6v7H18V14a2,2,0,0,0-4,0v7H10V14A6,6,0,0,1,16,8Z"
                transform="translate(-3.29 -3.999)"
                fill="none"
                stroke="#3d3d3d"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1.5"
              />
              <rect
                id="de389317-70ae-4164-985f-1f143c930bc1"
                width="4"
                height="12"
                transform="translate(-1.29 5.001)"
                fill="none"
                stroke="#3d3d3d"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1.5"
              />
              <circle
                id="f65f7f94-de06-47f2-9c6c-12297a7ddc9a"
                cx="2"
                cy="2"
                r="2"
                transform="translate(-1.29 -1.999)"
                fill="none"
                stroke="#3d3d3d"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1.5"
              />
            </g>
          </g>
        </svg>
      ),
    },
    {
      id: 4,
      url: "https://twitter.com/graphwizards",
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          strokeWidth="1.5"
          viewBox="0 0 22 22"
          stroke="currentColor"
          className="h-6 w-6"
        >
          <g id="Icon" transform="translate(0.75)">
            <rect id="Area" width="20" height="20" fill="#fcfcfc" opacity="0" />
            <g id="Icon-2" data-name="Icon" transform="translate(1.29 2.499)">
              <path
                id="_2ac03b52-6685-4b0f-86d0-93dd103fded6"
                data-name="2ac03b52-6685-4b0f-86d0-93dd103fded6"
                d="M20.909,2.727a9.9,9.9,0,0,1-2.854,1.391,4.073,4.073,0,0,0-7.146,2.727v.91A9.691,9.691,0,0,1,2.727,3.636S-.909,11.818,7.273,15.455A10.59,10.59,0,0,1,.909,17.273c8.182,4.545,18.182,0,18.182-10.455a4.056,4.056,0,0,0-.073-.754A7.017,7.017,0,0,0,20.909,2.727Z"
                transform="translate(-2.199 -3.373)"
                fill="none"
                stroke="#3d3d3d"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1.5"
              />
            </g>
          </g>
        </svg>
      ),
    },
  ];

  return (
    <>
      <Helmet>
        <title>
          Contact Us | Graphwizards, Patiala | Web & Graphic Design Services
        </title>
        <meta
          name="description"
          content="Get in touch with Graphwizards for web and graphic design services. Contact our creative agency in Patiala, Punjab."
        />
        <meta
          name="keywords"
          content="contact, Graphwizards, design agency contact, Patiala, Punjab, creative solutions, web design, graphic design"
        />
        <link rel="canonical" href="https://graphwizards.in/contact" />
      </Helmet>

      <Toaster closeButton />

      <section>
        <div className="min-h-screen bg-white">
          <div className="container max-w-7xl mx-auto px-5 md:px-0 pb-8 ">
            <div className="grid md:grid-cols-3 grid-cols-1 gap-5 pb-20 pt-32">
              {/* left */}
              <div className=" md:px-10 px-0 md:col-span-2">
                <h1 className="md:text-5xl text-3xl">
                  Let's Build an Awasome Project Together !
                </h1>

                <div>
                <form
  className={`grid md:grid-cols-2 grid-cols-1 md:gap-x-7 gap-y-14 mt-10 p-3 ${dissable ? 'opacity-70 pointer-events-none' : ''}`}
  onSubmit={handleSubmit}
>
                    <div>
                      <label htmlFor="name" className="text-lg">
                        Your Name*
                      </label>
                      <input
                        type="text"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        className="w-full border-b py-3 border-b-black outline-none focus:border-b-blue-600"
                        placeholder="Enter name"
                      />
                      {errors.name && (
                        <span className="text-red-500">{errors.name}</span>
                      )}
                    </div>

                    <div>
                      <label htmlFor="email" className="text-lg">
                        Your Email*
                      </label>
                      <input
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        className="w-full border-b py-3 border-b-black outline-none focus:border-b-blue-600"
                        placeholder="Enter email address"
                      />
                      {errors.email && (
                        <span className="text-red-500">{errors.email}</span>
                      )}
                    </div>

                    <div>
                      <label htmlFor="phone" className="text-lg">
                        Your Phone*
                      </label>
                      <input
                        type="number"
                        name="phone"
                        value={formData.phone}
                        onChange={handleChange}
                        className="w-full border-b py-3 border-b-black outline-none focus:border-b-blue-600"
                        placeholder="Enter phone number with country code"
                      />
                      {errors.phone && (
                        <span className="text-red-500">{errors.phone}</span>
                      )}
                    </div>

                    <div>
                      <label htmlFor="company" className="text-lg">
                        Your Company*
                      </label>
                      <input
                        type="text"
                        name="company"
                        value={formData.company}
                        onChange={handleChange}
                        className="w-full border-b py-3 border-b-black outline-none focus:border-b-blue-600"
                        placeholder="Enter company name"
                      />
                      {errors.company && (
                        <span className="text-red-500">{errors.company}</span>
                      )}
                    </div>

                    <div className="md:col-span-2">
                      <label htmlFor="message" className="text-lg">
                        Your Message*
                      </label>
                      <textarea
                        name="message"
                        value={formData.message}
                        onChange={handleChange}
                        className="w-full border-b py-3 border-b-black outline-none focus:border-b-blue-600"
                        placeholder="Type here..."
                      ></textarea>
                      {errors.message && (
                        <span className="text-red-500">{errors.message}</span>
                      )}
                    </div>

                    <div className="md:col-span-2 flex justify-end">
                      <button
                        type="submit"
                        className="px-5 py-2 bg-black text-white text-lg"
                      >
                        Send Message
                      </button>
                    </div>
                  </form>
                </div>
              </div>

              {/* right */}
              <div className="p-10 bg-gradient-to-tl from-blue-400 to-blue-600 rounded-lg text-white relative">
                <div>
                  <h3 className="text-lg font-semibold">Chat with us</h3>
                  <p className="text-sm text-white/60">
                    Speak to our friendly team via live chat.
                  </p>

                  <ul className="mt-6 space-y-2 underline">
                    <li className="">
                      <a className="flex items-center" href="https://api.whatsapp.com/send/?phone=8059112897&text&type=phone_number" target="BLANK()">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={2}
                        stroke="currentColor"
                        className="size-5 mr-3"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M20.25 8.511c.884.284 1.5 1.128 1.5 2.097v4.286c0 1.136-.847 2.1-1.98 2.193-.34.027-.68.052-1.02.072v3.091l-3-3c-1.354 0-2.694-.055-4.02-.163a2.115 2.115 0 0 1-.825-.242m9.345-8.334a2.126 2.126 0 0 0-.476-.095 48.64 48.64 0 0 0-8.048 0c-1.131.094-1.976 1.057-1.976 2.192v4.286c0 .837.46 1.58 1.155 1.951m9.345-8.334V6.637c0-1.621-1.152-3.026-2.76-3.235A48.455 48.455 0 0 0 11.25 3c-2.115 0-4.198.137-6.24.402-1.608.209-2.76 1.614-2.76 3.235v6.226c0 1.621 1.152 3.026 2.76 3.235.577.075 1.157.14 1.74.194V21l4.155-4.155"
                        />
                      </svg>
                      Start a live chat
                      </a>
                    </li>
                    <li >
                      <a className="flex items-center" target="BLANK()" href="mailto:hello@graphwizards.in">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={2}
                        stroke="currentColor"
                        className="size-5 mr-3"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M6 12 3.269 3.125A59.769 59.769 0 0 1 21.485 12 59.768 59.768 0 0 1 3.27 20.875L5.999 12Zm0 0h7.5"
                        />
                      </svg>
                      Shoot us an email
                      </a>
                    </li>
                    <li >
                      <a className="flex items-center" target="BLANK()" href="https://www.instagram.com/graph_wizards/?hl=en">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={2}
                        stroke="currentColor"
                        className="size-5 mr-3"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M7.5 8.25h9m-9 3H12m-9.75 1.51c0 1.6 1.123 2.994 2.707 3.227 1.129.166 2.27.293 3.423.379.35.026.67.21.865.501L12 21l2.755-4.133a1.14 1.14 0 0 1 .865-.501 48.172 48.172 0 0 0 3.423-.379c1.584-.233 2.707-1.626 2.707-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0 0 12 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018Z"
                        />
                      </svg>
                      Message on instagram
                      </a>
                    </li>
                  </ul>
                </div>

                <div className="mt-8">
                  <h3 className="text-lg font-semibold">Call us</h3>
                  <p className="text-sm text-white/60">Call our team Mon-Sat</p>
                  <ul className="mt-3">
                    <li className="flex items-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={2}
                        stroke="currentColor"
                        className="size-5 mr-3"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 0 0 2.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 0 1-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 0 0-1.091-.852H4.5A2.25 2.25 0 0 0 2.25 4.5v2.25Z"
                        />
                      </svg>
                      +91 8059112897
                    </li>
                  </ul>
                </div>

                <div className="mt-8">
                  <h3 className="text-lg font-semibold">Querry</h3>

                  <ul className="mt-3">
                    <li className="flex items-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={2}
                        stroke="currentColor"
                        className="size-5 mr-3"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M21.75 6.75v10.5a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25m19.5 0v.243a2.25 2.25 0 0 1-1.07 1.916l-7.5 4.615a2.25 2.25 0 0 1-2.36 0L3.32 8.91a2.25 2.25 0 0 1-1.07-1.916V6.75"
                        />
                      </svg>
                      hello@graphwizards.in
                    </li>
                  </ul>
                </div>

                <div className="h-12"></div>

                {/* social media icons */}

                <div className="absolute right-0 bottom-0">
                  <div className="relative pl-6 pt-4 bg-white  rounded-tl-lg  ">
                    <svg
                      id="Layer_1"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 86.57 86.18"
                      className="absolute h-3 w-3 bottom-0 -left-3 fill-white"
                    >
                      <path d="M86.57,0h0v86.18H0c22.85-.21,44.71-9.34,60.93-25.44,16.25-16.05,25.47-37.9,25.64-60.74Z" />
                    </svg>

                    <svg
                      id="Layer_1"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 86.57 86.18"
                      className="absolute h-3 w-3 -top-3 right-0 fill-white"
                    >
                      <path d="M86.57,0h0v86.18H0c22.85-.21,44.71-9.34,60.93-25.44,16.25-16.05,25.47-37.9,25.64-60.74Z" />
                    </svg>

                    <ul className="flex space-x-2">
                      {social.map((item, index) => (
                        <li
                          key={index}
                          className="text-slate-600 p-2 border rounded-lg border-slate-400 hover:bg-blue-50"
                        >
                          <a target="BLANK()" href={item.url}>{item.icon}</a>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            {/* faqs */}

            <div className="md:p-14 p-3 bg-gradient-to-br from-white via-blue-50 to-blue-100 rounded-xl ">
              <h2>FAQ's</h2>
              <p className="mt-2 text-md text-black/60 md:w-2/3">
                Find answers to common questions about GraphWizards Design Co.
                services, timelines, and how we help businesses in Patiala
                create stunning digital experiences.
              </p>

              <div className="mt-8 space-y-3">
                {/* 1 */}
                <div class="collapse collapse-plus bg-white    border">
                  <input type="radio" name="faqs" checked="checked" />
                  <div class="collapse-title text-lg font-semibold">
                    What services does GraphWizards Design Co. offer ?
                  </div>

                  <div class="collapse-content  bg-white border">
                    <div className="px-4 text-slate-500 py-5">
                      <p>
                        At GraphWizards Design Co., based in Patiala, we provide
                        a comprehensive range of services, including:
                      </p>
                      <div className="pl-5 mt-3">
                        <ul className=" list-disc space-y-2">
                          <li>Logo Design, Branding & Identity Development</li>
                          <li>
                            Packaging Design, Advertisement Design, Brochure
                            Design, and Flyer Design
                          </li>
                          <li>
                            Promo Video Creation, Motion Graphics, and Video
                            Editing
                          </li>
                          <li>
                            UI/UX Design, Web Design & Development, E-commerce
                            Development, and CMS Development
                          </li>
                          <li>
                            Amazon Product Listing, Social Media Marketing, SEO
                            & SEM, and Content Marketing
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>

                {/* 2 */}
                <div class="collapse collapse-plus bg-white border">
                  <input type="radio" name="faqs" />
                  <div class="collapse-title text-lg font-semibold">
                    How do I start a project with GraphWizards ?
                  </div>

                  <div class="collapse-content  bg-white border">
                    <div className="px-4 py-5 text-slate-500">
                      <p className="text-md">
                        To start a project with GraphWizards Design Co., located
                        in Patiala, you can contact us via our website or email.
                        After sharing your project details, we’ll schedule a
                        consultation to understand your vision and offer a
                        tailored solution that aligns with your business goals.
                      </p>
                    </div>
                  </div>
                </div>

                {/* 3 */}

                <div class="collapse collapse-plus bg-white   border">
                  <input type="radio" name="faqs" />
                  <div class="collapse-title text-lg font-semibold">
                    Which industries does GraphWizards Design Co. serve in
                    Patiala?
                  </div>

                  <div class="collapse-content  bg-swhite border">
                    <div className="px-4 text-slate-500 py-5">
                      <p className="text-md">
                        We proudly serve businesses across <b>Patiala</b> and
                        beyond, working with industries such as{" "}
                        <b> technology, healthcare, retail, hospitality, </b>{" "}
                        and <b>education</b>. Our expertise in design, branding,
                        and <b> digital marketing </b> helps businesses in these
                        sectors build strong brands and create exceptional
                        digital experiences.
                      </p>
                    </div>
                  </div>
                </div>

                {/* 4 */}
                <div class="collapse collapse-plus bg-white  border">
                  <input type="radio" name="faqs" />
                  <div class="collapse-title text-lg font-semibold">
                    How long does a typical project take at GraphWizards?
                  </div>

                  <div class="collapse-content  bg-white border">
                    <div className="px-4 text-slate-500 py-5">
                      <p className="text-md">
                        Project timelines vary depending on the scope and
                        complexity. For example, a logo design project can be
                        completed within a few days, while larger projects like
                        website development or full branding packages for
                        businesses in Patiala may take several weeks. We provide
                        a detailed timeline during the consultation phase.
                      </p>
                    </div>
                  </div>
                </div>

                {/* 5 */}
                <div class="collapse collapse-plus bg-white  border">
                  <input type="radio" name="faqs" />
                  <div class="collapse-title text-lg font-semibold">
                    Why choose GraphWizards Design Co. over other design
                    agencies?
                  </div>

                  <div class="collapse-content  bg-white border">
                    <div className="px-4 text-slate-500 py-5">
                      <p className="text-md">
                        GraphWizards Design Co. stands out among design agencies
                        in Patiala for our personalized approach, creativity,
                        and attention to detail. We are committed to delivering
                        high-quality results, whether it’s a website design, SEO
                        strategy, or a complete branding package. Our goal is to
                        help your business in Patiala grow and succeed through
                        exceptional design and marketing solutions.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
