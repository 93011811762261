import React, { useState, useRef, useEffect } from "react";
import { workData } from "../data";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation"; // Navigation module styles
import { Navigation } from "swiper/modules";
import { Link } from "react-router-dom";

import { pb } from "../pb";

export const LatestWork = () => {
  const prevRef = useRef(null);
  const nextRef = useRef(null);
  const [projectList, setProjectList] = useState();
  const [error, setError] = useState();

  // Function to fetch project list
  const getProjectList = async () => {
    try {
      // Fetch projects where featured is true and get specific fields
      const resultList = await pb.collection("project").getFullList({
        filter: "featured=true", // Only fetch featured projects

        expand: "cat, client", // Expand the 'cat' relation
        sort: "number", // Sort by the 'number' field
      });

      // Update state with the result
      console.log(resultList);
      setProjectList(resultList);
    } catch (error) {
      // Handle error and store it in state
      console.error("Error fetching project list:", error);
      setError("Failed to fetch project list");
    }
  };

  // useEffect to call getProjectList on component mount
  useEffect(() => {
    getProjectList();
  }, []);

  return (
    <section className="bg-white">
      <div className="container mx-auto max-w-7xl py-32 px-5 md:px-0 md:columns-2 md:gap-6 space-y-6">
        <div className=" pb-10 break-inside-avoid  p-5">
          <p className="font-semibold text-blue-600">OUR PORTFOLIO</p>
          <h2>
            Showcasing Our. <br /> Latest Creative Masterpieces
          </h2>
          <p className="font-extralight text-black/60 mt-4">
            Discover our recent projects, featuring dynamic websites and unique
            brand identities, all crafted with a focus on impact and results.
          </p>
        </div>

        {/* work grid */}

        {projectList &&
          projectList.map((item, index) => (
            <div
              key={index}
              className="group md:rounded-xl rounded-lg  break-inside-avoid"
            >
              <Link to={"/portfolio/" + item.seo_url}>
                <div className="rounded-lg relative overflow-hidden ">
                  <div className="absolute">
                    <div className="relative bg-white pr-6   rounded-br-[1.5rem] py-4 space-x-3 transform -translate-y-full group-hover:-translate-y-0  duration-150">
                      {/* bottom svg */}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className=" fill-white h-6 w-6 absolute left-0  -bottom-6"
                        viewBox="0 0 33.131 33.234"
                      >
                        <path
                          id="Subtraction_1"
                          data-name="Subtraction 1"
                          d="M0,33.234H0V0H33.13A33.474,33.474,0,0,0,0,33.234Z"
                          transform="translate(0.001)"
                        />
                      </svg>

                      {/* right svg */}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="md:w-6 md:h-6 absolute md:-right-6 h-4 w-4 -right-4  top-0 fill-white"
                        viewBox="0 0 33.131 33.234"
                      >
                        <path
                          id="Subtraction_1"
                          data-name="Subtraction 1"
                          d="M0,33.234H0V0H33.13A33.474,33.474,0,0,0,0,33.234Z"
                          transform="translate(0.001)"
                        />
                      </svg>

                      {item.expand.cat &&
                        item.expand.cat.map((item) => (
                          <span className="px-4 py-1.5 text-slate-700 rounded-full bg-slate-200/60 hover:bg-black hover:text-white text-sm">
                            {item.name}
                          </span>
                        ))}
                    </div>
                  </div>

                  <div className="absolute bottom-0 right-0 duration-150 transform translate-y-full group-hover:translate-y-0">
                    <div className="pt-3 pl-3 rounded-tl-xl bg-white shadow-xl  ">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={2}
                        stroke="currentColor"
                        className="size-7 "
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="m4.5 19.5 15-15m0 0H8.25m11.25 0v11.25"
                        />
                      </svg>

                      <svg
                        id="Layer_1"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 86.57 86.18"
                        className="absolute h-4 w-4 bottom-0 -left-4 fill-white"
                      >
                        <path d="M86.57,0h0v86.18H0c22.85-.21,44.71-9.34,60.93-25.44,16.25-16.05,25.47-37.9,25.64-60.74Z" />
                      </svg>

                      <svg
                        id="Layer_1"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 86.57 86.18"
                        className="absolute h-4 w-4 -top-4 right-0 fill-white"
                      >
                        <path d="M86.57,0h0v86.18H0c22.85-.21,44.71-9.34,60.93-25.44,16.25-16.05,25.47-37.9,25.64-60.74Z" />
                      </svg>
                    </div>
                  </div>
                  <img
                    src={`${process.env.REACT_APP_FILES_URL}${item.collectionId}/${item.id}/${item.mobile_banner}`}
                    alt={item.name}
                    className="w-full rounded-[1rem] block md:hidden"
                  />

                  <img
                    src={`${process.env.REACT_APP_FILES_URL}${item.collectionId}/${item.id}/${item.banner}`}
                    alt={item.name}
                    className="w-full rounded-[1rem] hidden md:block"
                  />
                </div>
                <div className="px-4 py-3">
                  <p className="text-xl font-light mt-3 group-hover:underline">
                    {item.title}
                  </p>
                  <p className="text-semibold text-lg text-blue-600">
                    {item.expand.client && item.expand.client.name}
                  </p>
                </div>{" "}
              </Link>
            </div>
          ))}
      </div>
    </section>
  );
};
