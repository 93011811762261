import React from "react";
const currentYear = new Date().getFullYear();


export const Footer = () => {
  return (
    <div className=" bg-black min-h-96   relative">
      <svg
        id="Isolation_Mode"
        xmlns="http://www.w3.org/2000/svg"
        className=" md:h-10 h-8    absolute md:-top-10 -top-8 right-0 fill-black"
        viewBox="0 0 86.57 86.18"
      >
        <path d="M86.57,0h0v86.18H0c22.85-.21,44.71-9.34,60.93-25.44,16.25-16.05,25.47-37.9,25.64-60.74Z" />
      </svg>

      <svg
        id="Isolation_Mode"
        xmlns="http://www.w3.org/2000/svg"
        className=" md:h-10 h-8    absolute md:-top-10 -top-8 -left-1 fill-black transform rotate-90"
        viewBox="0 0 86.57 86.18"
      >
        <path d="M86.57,0h0v86.18H0c22.85-.21,44.71-9.34,60.93-25.44,16.25-16.05,25.47-37.9,25.64-60.74Z" />
      </svg>

      {/* ///[footer content] */}

      <div className=" pt-20 pb-5 ">
        <div className="container mx-auto px-10 md:px-0 grid md:grid-cols-5 grid-cols-2 gap-3">
          <div className=" col-span-2 text-center md:text-left">
            <h2 className="md:text-5xl text-4xl font-extralight text-white">
              Do you like <br />
              what you see?
            </h2>
            <div className="px-6 py-2 rounded-full bg-blue-600 inline-block text-lg font-extralight text-white mt-4">
              Start a project
            </div>

            {/* sortlisted icon */}
          <div className="mt-5 opacity-50 hover:opacity-100">
          <a
              href="https://www.sortlist.com/agency/graphwizards"
              style={{
                display: "inline-block",
                width: "210px",
                height: "60px",
              }}
              target="BLANK()"
            >
              <img
                src="https://core.sortlist.com//_/apps/core/images/badges-en/badge-flag-black-light-xl.svg"
                alt="flag"
                style={{ width: "100%", height: "100%" }} // Optional: To make sure the image fits the container
              />
            </a>
          </div>
          </div>

          <div className="hidden md:block">
            <p className="text-white/50 text-sm">LEARN</p>
            <ul className="text-white text-xl mt-3  font-extralight space-y-2">
              <li className="text-white/80 hover:text-white ">About</li>
              <li className="text-white/80 hover:text-white ">Testimonials</li>
              <li className="text-white/80 hover:text-white ">Processes</li>
              <li className="text-white/80 hover:text-white ">FAQs</li>
              <li className="text-white/80 hover:text-white ">Blogs</li>
            </ul>
          </div>

          <div className=" hidden md:block">
            <p className="text-white/50 text-sm">EXPLORE</p>
            <ul className="text-white md:text-xl mt-3  font-extralight space-y-2 ">
              <li className="text-white/80 hover:text-white ">Home</li>
              <li className="text-white/80 hover:text-white ">Portfolio</li>
              <li className="text-white/80 hover:text-white ">Services</li>
              <li className="text-white/80 hover:text-white ">Carrers</li>
              <li className="text-white/80 hover:text-white ">Contact</li>
            </ul>
          </div>
          <div className="col-span-2 md:col-span-1 mt-10 md:mt-0 text-center md:text-left">
            <p className="text-white/50 text-sm ">GET IN TOUCH</p>
            <ul className="text-white md:text-xl mt-3  font-extralight space-y-2 text-md">
              <li className=" ">+91 8283049483</li>
              <li className=" ">hello@graphwizards.in</li>
              <li className=" ">
                SCO 117 Urben Estate Phase 2, Patiala Punjab
              </li>
            </ul>
          </div>
        </div>
        <footer  className="text-center  pt-5">
      <span className="text-sm text-white/50">© {currentYear} All rights reserved. GraphWizards Design Co.</span>
    </footer>
      </div>
    </div>
  );
};
