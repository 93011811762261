import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import ReactGA from "react-ga4";

const root = ReactDOM.createRoot(document.getElementById('root'));


ReactGA.initialize("G-SPZHCRTRNV"); 

// Track pageview on initial load
ReactGA.send("pageview");


root.render(
  
    <App />

);


reportWebVitals();
