export const servicesData = [
  {
    id: 1,
 
    name: "Graphics Designing",
    description:
      "Our graphic design services help bring your brand to life. We craft compelling visual assets that resonate with your target audience. From logos and branding materials to marketing collateral like brochures, flyers, and posters, we ensure every design is not only beautiful but also strategically aligned with your brand’s message.",
    image:
      "https://images.unsplash.com/photo-1609921212029-bb5a28e60960?q=80&w=3504&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  },
  {
    id: 2,
 
    name: "Web Designing & Development",
    description: "We create visually stunning and highly functional websites tailored to your brand’s unique needs. Our team specializes in responsive design, ensuring your site looks great on all devices. From concept to launch, we handle it all—custom designs, user experience (UX) optimization, and content management systems (CMS) integration. ",
    image: "https://images.unsplash.com/photo-1457305237443-44c3d5a30b89?q=80&w=3548&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  },
  {
    id: 3,
 
    name: "Brand Identity & Strategy",
    description: "Your brand is more than just a logo—it’s an experience. We work with you to develop a cohesive brand identity that includes logo design, color palettes, typography, and brand guidelines. Our branding strategy services help establish a strong, memorable brand presence that stands out in your industry.",
    image: "https://images.unsplash.com/photo-1561070791-2526d30994b5?q=80&w=2000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  },
  {
    id: 4,
    name: "UI/UX Design",
    description: "We believe in designing user-centric interfaces that offer an intuitive and engaging experience. Our UI/UX design services focus on enhancing user satisfaction by improving the usability, accessibility, and overall interaction with your digital product. Whether it’s a website, mobile app, or software, we create designs that users love.",
    image: "https://images.unsplash.com/photo-1541462608143-67571c6738dd?q=80&w=3540&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  }
];


export const workData = [
  {
    id : 1,
    title : "Midnight Melodies: The Jazz Chronicles",
    image : "https://cdn.dribbble.com/userupload/4445740/file/original-32d62980a2e6e41c12ea4f68031a6235.png?resize=1504x1128",
    client : "The Jazz Chronicles",
    tags : ['Book Cover', 'Graphic Design']
  },

  {
    id : 2,
    title : "Poland Photography Portfolio",
    image : "https://cdn.dribbble.com/userupload/13123897/file/original-1b6e1fa9ddaf3d32673e3710d93017ac.png?resize=2048x1536",
    client : "Master Templates",
    tags : ['UI/UX', 'Web Designing']
  },

  {
    id : 3,
    title : "Kinobody | Packaing design for supplements",
    image : "https://cdn.dribbble.com/userupload/15383570/file/original-60fbbbb2f2ec72ffa8330ad7a3431044.jpg?resize=2048x1536",
    client : "Marina Zakharova",
    tags : ['Packaging']
  },

  {
    id : 4,
    title : "Fitness App Design Concept",
    image : "https://cdn.dribbble.com/userupload/11363174/file/original-f376e8b87fa11a3afb5657bcbeda9bca.png?resize=2048x1536",
    client : "Dmitry Lauretsky for Ronas IT | UI/UX Team",
    tags : ['UI/UX', 'Development']
  },
]


export const cat = ['explore all', 'fashion', 'fitness & sport', 'education', 'health', 'property', 'corporate', 'food & drinks', 'agency', 'pharma' ,  'ecommerce', 'b2b', 'archive']
