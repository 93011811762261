import React from 'react'

import { Helmet } from 'react-helmet';

export const Blogs = () => {
  return (
    <>
       <Helmet>
        <title>Blogs | Design Tips & Industry Insights | Graphwizards</title>
        <meta name="description" content="Discover design tips, web and graphic design insights, and industry news from Graphwizards, your creative agency in Patiala, Punjab." />
        <meta name="keywords" content="design blog, web design tips, graphic design insights, Graphwizards, creative agency Patiala" />
        <link rel="canonical" href="https://graphwizards.in/blogs" />
      </Helmet>
      
    </>
  )
}
