import React, { useRef, useState, useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css"; // Import AOS styles
import { Hero } from "../comp/Hero";
import { Helmet } from "react-helmet";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css"; // Basic Swiper styles
import "swiper/css/navigation"; // Navigation module styles

// Import the Navigation module correctly
import { Navigation, Autoplay } from "swiper/modules";

import { Services } from "../comp/Services";
import { Companies } from "../comp/Companies";
import { LatestWork } from "../comp/LatestWork";

import { pb } from "../pb";
import { Link } from "react-router-dom";

export const Home = () => {
  const prevRef = useRef(null);
  const nextRef = useRef(null);

  const [sliderData, setSliderData] = useState();
  const [error, setError] = useState(null);

  // Function to fetch active slider data
  const fetchActiveSliders = async () => {
    try {
      // Fetch data with filter where slide is 'active' and only retrieve 'slider' field
      const resultList = await pb.collection("project").getFullList({
        filter: "slide = true", // Filter where slide is active
        fields: "slider, collectionId, id, mobile_banner", // Retrieve only the 'slider' field
        sort: "number", // Sorting if needed
      });

      // Log and set the data in the state
      console.log(resultList);
      setSliderData(resultList); // Set slider data in state
    } catch (error) {
      // Handle error and set error state
      console.error("Error fetching slider data:", error);
      setError("Failed to fetch slider data");
    }
  };

  // useEffect to call fetchActiveSliders on component mount
  useEffect(() => {
    fetchActiveSliders();
    AOS.init();
  }, []);

  return (
    <div className="bg-white">
      <Helmet>
        <title>
          Creative Graphic & Web Design Agency | Graphwizards
        </title>
        <meta
          name="description"
          content="Graphwizards is a creative design agency based in Patiala, Punjab. We offer stunning graphic and web design services to elevate your brand."
        />
        <meta
          name="keywords"
          content="Graphwizards, graphic design, web design, design agency, Patiala, creative agency, Punjab"
        />
        <link rel="canonical" href="https://graphwizards.in/" />
      </Helmet>

      <Hero />

      <div className="bg-white pt-32 ">
        <div className=" ">
          <div className="bg-black relative  rounded-tl-[1.5rem] md:rounded-tl-[3rem]  ">
            <svg
              id="Isolation_Mode"
              xmlns="http://www.w3.org/2000/svg"
              className=" md:h-16 h-8    absolute md:-top-16 -top-8 right-0 fill-black"
              viewBox="0 0 86.57 86.18"
            >
              <path d="M86.57,0h0v86.18H0c22.85-.21,44.71-9.34,60.93-25.44,16.25-16.05,25.47-37.9,25.64-60.74Z" />
            </svg>

            <div className="container mx-auto px-5 md:px-0">
              {/* ///[slider container] */}
              <div className="flex md:flex-row flex-col-reverse">
                <div className="w-full md:w-4/12 pt-0 md:pt-10">
                  <div className="pr-0 md:pr-8 ">
                    <h2
                      className="text-white -mt-20 md:mt-10 "
                      data-aos="fade-up"
                      data-aos-offset="130"
                    >
                      Showcasing Creativity and Innovation in Every Design
                    </h2>
                    <p
                      className="text-white/40 font-light mt-3 md:mt-6"
                      data-aos="fade-up"
                      data-aos-offset="150"
                    >
                      Discover our recent projects, featuring dynamic websites
                      and unique brand identities, all crafted with a focus on
                      impact and results.
                    </p>

                    <Link to={'/portfolio'} className="mt-6 text-white flex items-center  group w-min text-nowrap" data-aos="fade-up">
                      View Portfolio
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="size-6 ml-3 transform group-hover:translate-x-4 duration-150"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M17.25 8.25 21 12m0 0-3.75 3.75M21 12H3"
                        />
                      </svg>
                    </Link>
                  </div>
                </div>

                <div
                  className="md:w-8/12 w-full"
                  data-aos="fade-up"
                  data-aos-offset="0"
                >
                  {sliderData && (
                    <div className="md:rounded-[1.5rem] rounded-2xl overflow-hidden transform -translate-y-32 bg-slate-50 relative">
                      {sliderData.length > 1 && (
                        <div className="absolute   z-10">
                          <div className=" md:py-3 py-2 bg-white  md:px-12 px-8 rounded-br-lg md:rounded-br-[1.5rem]   relative">
                            {/* corners */}

                            {/* bottom svg */}
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="md:w-8 md:h-8 w-4 -4 absolute md:-bottom-8 -bottom-4  left-0 fill-white"
                              viewBox="0 0 33.131 33.234"
                            >
                              <path
                                id="Subtraction_1"
                                data-name="Subtraction 1"
                                d="M0,33.234H0V0H33.13A33.474,33.474,0,0,0,0,33.234Z"
                                transform="translate(0.001)"
                              />
                            </svg>

                            {/* right svg */}
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="md:w-8 md:h-8 absolute md:-right-8 h-4 w-4 -right-4  top-0 fill-white"
                              viewBox="0 0 33.131 33.234"
                            >
                              <path
                                id="Subtraction_1"
                                data-name="Subtraction 1"
                                d="M0,33.234H0V0H33.13A33.474,33.474,0,0,0,0,33.234Z"
                                transform="translate(0.001)"
                              />
                            </svg>

                            <div className="flex space-x-4  ">
                              <div
                                className="cursor-pointer hover:scale-150 duration-150"
                                ref={prevRef}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  strokeWidth={1.5}
                                  stroke="currentColor"
                                  className="md:size-8 size-6"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M6.75 15.75 3 12m0 0 3.75-3.75M3 12h18"
                                  />
                                </svg>
                              </div>

                              <div
                                ref={nextRef}
                                className="cursor-pointer hover:scale-150 duration-150"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  strokeWidth={1.5}
                                  stroke="currentColor"
                                  className="md:size-8 size-6"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M17.25 8.25 21 12m0 0-3.75 3.75M21 12H3"
                                  />
                                </svg>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}

                      <Swiper
                        grabCursor={true}
                        modules={[Navigation]}
                        onInit={(swiper) => {
                          swiper.params.navigation.prevEl = prevRef.current;
                          swiper.params.navigation.nextEl = nextRef.current;
                          swiper.navigation.init();
                          swiper.navigation.update();
                        }}
                       
                        spaceBetween={0}
                        slidesPerView={1}
                        loop={true} // Enable loop if needed
                      >
                        {/* Swiper slides */}
                        {sliderData.map((slide) => (
                          <SwiperSlide>
                            <img
                              src={`${process.env.REACT_APP_FILES_URL}${slide.collectionId}/${slide.id}/${slide.slider}`}
                              className="w-full hidden md:lg:xl:2xl:block"
                            />

                            <img
                              src={`${process.env.REACT_APP_FILES_URL}${slide.collectionId}/${slide.id}/${slide.mobile_banner}`}
                              className="w-full md:lg:xl:2xl:hidden block"
                            />
                          </SwiperSlide>
                        ))}
                      </Swiper>
                    </div>
                  )}
                </div>
              </div>

              <Services />
            </div>
          </div>
        </div>
      </div>

      {/* ///[services] */}

      <LatestWork />
 

      <Companies />
    </div>
  );
};
