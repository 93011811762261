import React from 'react'

export const Loader = () => {
  return (
    <div className='absolute h-screen w-full bg-white z-50 top-0'>
        <div className='w-full h-full flex flex-wrap justify-center content-center'>
        <span class="loading loading-spinner loading-lg"></span>
        </div>
    </div>
  )
}
