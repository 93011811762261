import PocketBase from 'pocketbase';

export const pb = new PocketBase(process.env.REACT_APP_PB_URL);

// globally disable auto cancellation
pb.autoCancellation(false);

async function getProjectList() {
    try {
        const resultList = await pb.collection('project').getList(1, 50, {
            filter: 'created >= "2022-01-01 00:00:00" && someField1 != someField2',
        });

        // Return the result on successful fetch
        return resultList;
    } catch (error) {
        // Handle the error gracefully
        console.error('Error fetching project list:', error);
        return { success: false, message: 'Failed to retrieve project list', error };
    }
}