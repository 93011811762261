import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { pb } from '../pb';

const SaveUTM = () => {
  const location = useLocation();

  // Function to detect device information using navigator
  const getDeviceInfo = () => {
    const userAgent = navigator.userAgent;

    let deviceType = 'Desktop';
    if (/Mobi|Android/i.test(userAgent)) {
      deviceType = 'Mobile';
    }

    const browserInfo = {
      userAgent,
      deviceType,
      platform: navigator.platform, // e.g., Win32, MacIntel, Linux
      language: navigator.language, // Browser language
    };

    return browserInfo;
  };


    // Function to get IP information from ipapi
    const getIPInfo = async () => {
      try {
        const response = await fetch('https://ipapi.co/json/');
        const ipData = await response.json();
        return ipData;
      } catch (error) {
        console.error('Error fetching IP information:', error);
        return null;
      }
    };

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);

    // Extract UTM parameters from URL
    const utm_source = searchParams.get('utm_source') || 'unknown';
    const utm_campaign = searchParams.get('utm_campaign') || 'unknown';
    const utm_medium = searchParams.get('utm_medium') || 'unknow';
    const current_url = window.location.href; // Get the current URL
    const deviceInfo = getDeviceInfo(); // Get device info

    // Check if UTM data is already saved in this session
    const utmSaved = sessionStorage.getItem('utm_saved');

    if (!utmSaved && (utm_source !== 'unknown')) {
      const saveUTMData = async () => {
        try {

          const ipInfo = await getIPInfo(); 


          const record = await pb.collection('utm').create({
            source: utm_source,
            campaign: utm_campaign,
            medium : utm_medium,
            ip_address: ipInfo ? ipInfo.ip : 'unknown', // Save IP address
            ip_info : ipInfo ? JSON.stringify(ipInfo)  : 'unknown' ,
            url: current_url, // Save the current URL
            deviceInfo: JSON.stringify(deviceInfo), // Save device info as a string
          });
     
          // Set session flag to prevent further saves
          sessionStorage.setItem('utm_saved', 'true');
        } catch (error) {
          console.error('Error saving UTM parameters, URL, and device info:', error);
        }
      };

      saveUTMData();
    }
  }, [location.search]); // Run when the URL search changes

  return null; // No need to render anything
};

export default SaveUTM;