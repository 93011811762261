import React, { useEffect } from "react";
import banner from "./../img/banner.jpg";
import AOS from "aos";
import "aos/dist/aos.css"; // Import AOS styles
import google from './../img/google.svg'

export const Hero = () => {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <div className="bg-white px-5 md:px-0">
      <div className="container mx-auto">
        <div className="flex md:flex-wrap py-20 md:py-0 md:content-center h-full md:min-h-[650px]">
          {/* Primary Content Section */}
          <div className="text-left" data-aos="fade-in" data-aos-duration="2000">
              {/* Supporting Subheading */}
              <h2 className="mb-2 md:text-3xl text-lg font-light">
              Transform Your Vision into Reality
            </h2>
            {/* Main Heading */}
            <h1 className="font-bold md:text-7xl text-5xl mb-4">
              Stunning Digital Experiences
            </h1>
          
            {/* Supporting Text */}
            <p className="text-neutral-400 mt-4 md:text-lg">
              Ready to bring your ideas to life? Let’s create captivating designs together. <br />
              Start your project with us today!
            </p>

            {/* Google Rating Link */}
            <div className="mt-8">
              <a 
                target="_blank" 
                rel="noopener noreferrer" 
                href="https://business.google.com/v/_/AKsrIN1oS2l0RQVtJKqhfdR0KuKRXifpVbmlu5e0o7vy7sJuFga3Qss/d8f7/_?exp=1778401&myads=1"
              >
                <img 
                  className="md:h-10 h-7" 
                  src={google}
                  alt="Google Business Rating - 5 Stars" 
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};